.circles_floating {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

@keyframes animate {
    0% {
        opacity: 1;
        border-radius: 0;
        transform: translateY(0) rotate(0deg);
    }

    100% {
        opacity: 0;
        border-radius: 50%;
        transform: translateY(400px) rotate(720deg);

    }
}

.circles_floating div {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    top: -150px;
    animation: animate 20s linear infinite;
}

.circles_floating div:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;

}

.circles_floating div:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;


}

.circles_floating div:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;


}

.circles_floating div:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;


}

.circles_floating div:nth-child(5) {
    left: 65%;
    width: 25px;
    height: 25px;
    animation-delay: 0s;



}

.circles_floating div:nth-child(6) {
    left: 75%;
    width: 40px;
    height: 40px;
    animation-delay: 3s;



}

.circles_floating div:nth-child(7) {
    left: 35%;
    width: 80px;
    height: 80px;
    animation-delay: 7s;


}

.circles_floating div:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;


}

.circles_floating div:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;


}

.circles_floating div:nth-child(10) {
    left: 85%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(11) {
    left: 5%;
    width: 25px;
    height: 25px;
    animation-delay: 0s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(12) {
    left: 0%;
    width: 40px;
    height: 40px;
    animation-delay: 2s;
    animation-duration: 16s;


}

.circles_floating div:nth-child(13) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 1s;
    animation-duration: 11s;


}

.circles_floating div:nth-child(11) {
    left: 15%;
    width: 25px;
    height: 25px;
    animation-delay: 7s;
    animation-duration: 11s;


}

.sidebar_overflow {
    overflow: auto !important;
}

.navbar-brand-box {
    background-color: rgb(255, 255, 255) !important;
    width: 100%;
    padding: 0 !important;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.errorMessage {
    color: red;
}

.card_dash {
    font-size: 12px !important;
}

.tt {
    text-align: right;
}

.project_filter {
    font-size: 15px;
}

.form_divide hr {
    /* width: 80% !important; */
    margin-bottom: 20px;
}

.max_char {
    font-size: 10px !important;
    color: rgb(139, 138, 135);
    word-break: break-all;
}

.required_field {
    color: rgb(245, 10, 10);
}

.unique_id {
    width: 20% !important;
    margin-left: 5px;
    border: 1px solid rgb(138, 134, 134);
    /* padding: 1px; */
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;

}

.client_active_btn {
    gap: 10px;
}

.client_active_btn .search {

    text-align: right;
    position: absolute;
    justify-content: end;
    align-items: end;
    right: 30px;
    width: 280px !important;
}

.table_client {
    text-align: center !important;
    vertical-align: middle !important;
}

.active_btn {
    color: rgba(var(--vz-success-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.inactive_btn {
    color: rgba(var(--vz-warning-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.closed_btn {
    color: rgba(var(--vz-danger-rgb), var(--vz-bg-opacity)) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
}

.Invoiced_btn {

    background-color: rgb(174, 0, 255);
    color: rgb(174, 0, 255) !important;
    width: 10px;
    height: 10px;
    padding: 0px !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.add_qustion {
    width: 100px;
}

.container-fluid .btn {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
    border: none !important;
}

.card {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.report .form-check {
    display: block;
    min-height: 1.21875rem;
    padding-left: 5.600000000000001em;
    margin-bottom: 0.125rem;
    margin-top: 25px;
}

.report_btn {
    margin-top: 20px;
}

.report_btn .btn {
    width: 100px !important;
}

.page_size_support {
    display: flex;
}

.page_size_support .form-select {
    width: 50%;
    height: 30px;
    margin-left: 5px;

}

.mt-8_rec {
    margin-top: 55px;
}

.fs-8_profile {
    font-size: 34px;
}

.user-name-text-p {
    color: white;
}

.user-name-sub-text-p {
    color: rgb(0, 0, 0) !important;
    font-weight: 600;
}

.country_allowed {
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 500;
    color: #303d67;
}

.optionListContainer_div .optionListContainer {
    transform: ease;
    overflow: auto !important;
    z-index: 99;
    height: 90px !important;
}

/* user add css */
.card_user {
    background-color: #f3f3f9 !important;

    
}

.card_user .search {
    width: 40%;
}

.user_sec .user_btn .btn {
    width: 80px;
    border-radius:5px;
    box-shadow: none !important;
    border: 1px solid rgb(8, 8, 8);
}

.user_sec p {
    margin-bottom: 3px;
    margin-top: 0px !important;
}
.module_user p{
    cursor: pointer !important;
}
.module_user p:hover{
    cursor: pointer !important;
    color:#303d67 !important ;
}
.btn-light-module{
    background-color: #ffffff;
    border: none !important;
}
.btn-light-module:hover{
    color:#303d67 !important ;
}
.btn-light-module:active{
    color:#303d67 !important ;
}
.btn-light-module::after{
    color:#303d67 !important ;
}

.user_sec span {
    font-size: 13px;
    color: #4a4e4eb4;
    font-weight: 500;
}
.lower_div{
    background-color: #f3f3f9;
}
.scrool {
    overflow-y: auto !important;
    height: 90vh;
    padding-bottom: 70px;
}

.scrool {
    scrollbar-width: 3px !important;
}

.scrool::-webkit-scrollbar {
    width: 6px !important;    
}
.scrool::-webkit-scrollbar-thumb {
    background: #a5a5a8b9;
}
.sideFirstTab{
    font-size: 40px !important;
}
.bg-primary{
    background-color: #464d64 !important;
}

/* Table Scrollable */
/* .table-scroll-fixed .table thead th{position:sticky;z-index:99;}
.table-scroll-fixed .table tbody td{position:sticky;z-index:99;}
.table-scroll-fixed thead th:nth-child(1),.table-scroll-fixed thead th:nth-child(2),.table-scroll-fixed thead th:nth-child(3),.table-scroll-fixed thead th:nth-child(4){z-index:999 !important}
.table-scroll-fixed tbody td:nth-child(1),.table-scroll-fixed thead th:nth-child(1){position:-webkit-sticky;position:-moz-sticky;position:-ms-sticky;position:-o-sticky;position:sticky;left:0;z-index:999 !important}
.table-scroll-fixed tbody td:nth-child(2),.table-scroll-fixed thead th:nth-child(2){position:-webkit-sticky;position:-moz-sticky;position:-ms-sticky;position:-o-sticky;position:sticky;left:50px;z-index:999 !important}
.table-scroll-fixed tbody td:nth-child(3),.table-scroll-fixed thead th:nth-child(3){position:-webkit-sticky;position:-moz-sticky;position:-ms-sticky;position:-o-sticky;position:sticky;left:150px;z-index:999 !important}
.table-scroll-fixed tbody td:nth-child(4),.table-scroll-fixed thead th:nth-child(4){position:-webkit-sticky;position:-moz-sticky;position:-ms-sticky;position:-o-sticky;position:sticky;left:270px;z-index:999 !important}
.table thead th{white-space:nowrap} */

canvas {
    background-color: #ffffff;
    padding: 10px;
}